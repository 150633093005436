import React from "react";
import styles from "./Logo.module.css";
import logo from "../../assets/logo/logo.png";

const Logo = () => {
  return (
    <div className={styles.mainLogo}>
      <img className={styles.logo} src={logo} alt="Hplus Products" />
      <h2 className={styles.text}>www.hplusproducts.com</h2>
    </div>
  );
};
export default Logo;
