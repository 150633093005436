import React from "react";
import styles from "./footer.module.css";

const footer = () => {
  return (
    <div className={styles.mainFooter}>
      <p className={styles.messageInFooter}>Designed by Hamzavi Infotech</p>
    </div>
  );
};

export default footer;
