import React, { Component } from "react";

import MessageBox from "./components/messageBox/messageBox";
import Footer from "./components/footer/footer";
import Logo from "./components/logo/Logo";

class App extends Component {
  render() {
    return (
      <div>
        <Logo />
        <MessageBox />
        <Footer />
      </div>
    );
  }
}

export default App;
