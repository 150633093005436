import React from "react";
import styles from "./messageBox.module.css";

const messageBox = () => {
  return (
    <div className={styles.contentMessage}>
      <p className={styles.messageHeading}>Oops, still under maintainence!!!</p>
      <p className={styles.messageContent}>
        Your patience is much appreciated, we will be online again shortly
        <br />
        We are currently available in India, GCC, Indonesia and France
      </p>
    </div>
  );
};

export default messageBox;
